/**
 * Titel: DESIGN
 * Beskrivelse: metoder som udbedrer en række "shortcomings" i designet eller tilføjer ekstra features til elementer
 * Status: er en blanding af nyt og gammelt. ES5-syntax hele vejen igennem
 */

AU.design = {
    setFocusFieldIds: function () {
        jQuery('.au_focus').each(function (i) {
            if (!jQuery(this).attr('id')) {
                jQuery(this).attr('id', 'focusid_' + i);
            }
        });
    },
    setBuildingLinks: function (elementToSearchSelector) {
        var elementToSearch = jQuery(elementToSearchSelector);

        if (elementToSearch.length > 0) {
            var mapUrl = AU.pageLang === "da" ? '//www.au.dk/kort/' : '//www.au.dk/en/map/';
            var buildingPhrase = AU.pageLang === "da" ? 'bygning' : 'building';
            var re = new RegExp('(' + buildingPhrase + ' (\\d{4}))', 'ig');
            jQuery.each(elementToSearch, function () {
                jQuery(this).html(jQuery(this).html().replace(re, '<a href="' + mapUrl + '?b=$2">$1</a>'));
            });
        }
    },
    onCollapseOpen: [],
    onCollapseClose: [],
    setCollapse: function () {

        var openEvent = document.createEvent('Event');
        openEvent.initEvent('collapsible-open', true, true);

        var closeEvent = document.createEvent('Event');
        closeEvent.initEvent('collapsible-close', true, true);

        var collapsibles = jQuery('.csc-frame.au_collapsible');
        collapsibles.each(function () {
            var me = jQuery(this);
            var domElement = me.get(0);
            var header = jQuery('> .csc-header', me);

            header.unbind('click').on('click', function () {
                me.toggleClass('au_collapsed');
                if (me.hasClass('au_collapsed')) {
                    domElement.dispatchEvent(closeEvent);
                } else {
                    domElement.dispatchEvent(openEvent);
                }
            });

            var d = AU.design;
            domElement.addEventListener('collapsible-open', function (e) {
                for (var i = 0; i < d.onCollapseOpen.length; i++) {
                    if (typeof d.onCollapseOpen[i] === 'function') {
                        d.onCollapseOpen[i](e.target);
                    }
                }
            }, false);


            domElement.addEventListener('collapsible-close', function (e) {
                for (var i = 0; i < d.onCollapseClose.length; i++) {
                    if (typeof d.onCollapseClose[i] === 'function') {
                        d.onCollapseClose[i](e.target);
                    }
                }
            }, false);
        });

        this.openCollapsibleFromHash(location.hash);

        jQuery(window).on('hashchange', function () {
            AU.design.openCollapsibleFromHash(location.hash);
        });
    },
    openCollapsibleFromHash: function (hash) {
        if (hash.indexOf('c') === 1) {
            jQuery(hash + '.csc-frame.au_collapsible, ' + hash + ' > .csc-frame.au_collapsible, ' + hash + ' > .csc-frame.au_collapsible').removeClass('au_collapsed');
            jQuery(hash).parents('.au_collapsible').removeClass('au_collapsed');
            document.querySelector(hash).scrollIntoView();
        }
    },
    grayscale: function (src) {
        try {
            var supportsCanvas = !!document.createElement('canvas').getContext;
            if (supportsCanvas) {
                var canvas = document.createElement('canvas'),
                    context = canvas.getContext('2d'),
                    imageData, px, length, i = 0, gray,
                    img = new Image();

                img.src = src;
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);

                imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                px = imageData.data;
                length = px.length;

                for (; i < length; i += 4) {
                    gray = px[i] * 0.3 + px[i + 1] * 0.59 + px[i + 2] * 0.11;
                    px[i] = px[i + 1] = px[i + 2] = gray;
                }

                context.putImageData(imageData, 0, 0);
                return canvas.toDataURL();
            } else {
                return src;
            }
        } catch(err) {
            console.log(err);
            return src;
        }
    },
    setBannerElementsReady: function () {
        jQuery('.aubanner a').hover(
            function () {
                jQuery(this).find('.gotcolors').stop().animate({ opacity: 1 }, 200);
            },
            function () {
                jQuery(this).find('.gotcolors').stop().animate({ opacity: 0 }, 500);
            }
        );
    },
    setBannerElementsLoad: function () {
        jQuery('.aubanner:not(.aubanner-2) img').each(function () { // Fordi det er fuldstændig utænkeligt, at vi kan få fjernet Prototype fra pure.au.dk...
            var bc = jQuery('.aubanner-content', jQuery(this).parents('.aubanner'));
            bc.css('background-image', "url('" + AU.design.grayscale(this.src) + "')");
        }).animate({ opacity: 1 }, 500);
    },
    setHero: function () {
        var hero = jQuery('.hero');
        if (hero.length > 0) {
            jQuery(window).scroll(function () {
                var x = jQuery(this).scrollTop() + (jQuery('body').hasClass('layout13') ? 0 : -200);
                hero.css('background-position-y', parseInt(x / 10) + 'px');
            });
        }
    },
    setMagnificPopup: function () {
        var lightbox = jQuery('.csc-textpic a[rel*="lightbox"], .image a[rel*="lightbox"]');

        // http://stackoverflow.com/questions/6900958/using-jquery-to-perform-a-function-on-elements-with-unique-rel-attribute
        var rels = {};
        lightbox.each(function () {
            var r = jQuery(this).attr('rel');
            rels[r] = 1;
        });

        rels = Object.keys(rels);
        jQuery.each(rels, function (index, value) {
            jQuery('a[rel="' + value + '"]').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                },
                modal: false
            });
        });

        var newsImgs = jQuery('.au_news-single-sub-images a, .au_news-single-img a, .news-set1-img1all a');
        newsImgs.magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            },
            modal: false
        });
    },
    setTopWarning: function () {
        if (window.topWarning) {
            jQuery('body').prepend(jQuery('<div />', {
                'class': 'ie-warning',
                'html': AU.pageLang == 'da' ? window.topWarningTextDa : window.topWarningTextEn
            }));
        }
    }
};