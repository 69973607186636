/**
 * @author TRH
 */

/*** GLOBALT AU OBJEKT ***/

var AU = {
    pageLang: 'da', // Default sprog
    cdn: 'https://cdn.au.dk',
    version: '202204211100',
    bss: false,
    ready: [],
    addReadyFunction: function(fn) {
        if (typeof fn === 'function') {
            // Check if the function reference already exists in the ready array
            if (AU.ready.indexOf(fn) === -1) {
                AU.ready.push(fn);
            } else {
                console.log("Function already exists in AU.ready and will not be added again.");
            }
        } else {
            console.error("Attempted to add a non-function to AU.ready.");
        }
    },
    load: [],
    addLoadFunction: function(fn) {
        if (typeof fn === 'function') {
            // Check if the function reference already exists in the load array
            if (AU.load.indexOf(fn) === -1) {
                AU.load.push(fn);
            } else {
                console.log("Function already exists in AU.load and will not be added again.");
            }
        } else {
            console.error("Attempted to add a non-function to AU.load.");
        }
    },
    resize: [],
    addResizeFunction: function(fn) {
        if (typeof fn === 'function') {
            // Check if the function reference already exists in the resize array
            if (AU.resize.indexOf(fn) === -1) {
                AU.resize.push(fn);
            } else {
                console.log("Function already exists in AU.resize and will not be added again.");
            }
        } else {
            console.error("Attempted to add a non-function to AU.resize.");
        }
    },
    scroll: [],
    addScrollFunction: function(fn) {
        if (typeof fn === 'function') {
            // Check if the function reference already exists in the scroll array
            if (AU.scroll.indexOf(fn) === -1) {
                AU.scroll.push(fn);
            } else {
                console.log("Function already exists in AU.scroll and will not be added again.");
            }
        } else {
            console.error("Attempted to add a non-function to AU.scroll.");
        }
    },
    navigation: {}
};