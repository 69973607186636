/**
 * Titel: COOKIES
 * Beskrivelse: Hjælpemetoder til get/set af cookies samt initialisering af cookievarsling
 * Status: er ikke blevet ændret siden 2011-designet. Cookieadvarslen kunne med fordel skrives om til ES6/React
 */

AU.cookies = {
    set: function (c_name, value, exdays, path) {
        if (typeof path === 'undefined') {
            path = ';path=/;domain=au.dk';
        }

        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = encodeURI(value) + ((exdays === null) ? '' : '; expires=' + exdate.toUTCString());
        document.cookie = c_name + '=' + c_value + path;
    },
    get: function (c_name) {
        var c_value = document.cookie;
        var c_start = c_value.indexOf(' ' + c_name + '=');
        if (c_start === -1) {
            c_start = c_value.indexOf(c_name + '=');
        }
        if (c_start === -1) {
            c_value = null;
        }
        else {
            c_start = c_value.indexOf('=', c_start) + 1;
            var c_end = c_value.indexOf(';', c_start);
            if (c_end === -1) {
                c_end = c_value.length;
            }
            c_value = decodeURI(c_value.substring(c_start, c_end));
        }
        return c_value;
    },
    alertElement: null,
    noAlertPeriod: 365,
    strings: {
        header: '',
        paragraph: '',
        button: ''
    },
    init: function () {
        if (window.alertCookies) {
            var replaceCookieTexts = function (text) {
                return text.replace('{0}', AU.cookies.strings.header).replace('{1}', AU.cookies.strings.paragraph).replace('{2}', AU.cookies.strings.button);
            };

            var alertElement = document.createElement('div');
            alertElement.setAttribute('id', 'au_cookiesalert');
            alertElement.innerHTML = replaceCookieTexts('<h1>{0}</h1><p>{1}</p><p class="button" id="au_cookiesalert_yes"><button>{2}</button></p>');
            var globalFooter = document.querySelector('footer.global');
            if (globalFooter) {
                globalFooter.insertAdjacentElement('afterend', alertElement);
                alertElement.style.display = 'none';
                this.alertElement = alertElement;
            }

            var allowCookies = this.get('allow_cookies');
            if (allowCookies === null) {
                this.alert();
            }
        }
    },
    initCookieScript: function () {
        if (typeof window.cookieScriptId !== 'undefined') {
            // Insert scripts and CSS for Pop Up
            var head = document.head;
            var cookieScriptManipulationCode = document.createElement('script');
            cookieScriptManipulationCode.setAttribute('src', '//cdn.au.dk/res/cookiescript/cookie-modal.min.js?a=' + (typeof window.cookieScriptVersion !== 'undefined' ? window.cookieScriptVersion : '1'));
            cookieScriptManipulationCode.setAttribute('charset', 'UTF-8');
            cookieScriptManipulationCode.setAttribute('type', 'text/javascript');
            head.insertBefore(cookieScriptManipulationCode, head.firstElementChild);
        }
    },
    alert: function () {
        if (this.alertElement !== null) {
            this.alertElement.style.display = 'block';

            document.querySelector('#au_cookiesalert_yes button').addEventListener('click', function (e) {
                e.preventDefault();
                AU.cookies.accept(AU.cookies.noAlertPeriod, true);
            });
        }
    },
    accept: function (duration, byGUI) {
        var path = location.host.indexOf('au.dk') > -1 ? undefined : ';path=/';
        this.set('allow_cookies', 'true', duration, path);
        if (byGUI) {
            this.alertElement.style.display = 'none';
        }
    }
};