// Default søgeboks til medarbejderportaler til utility-box
const DEFAULT_util_staffportal_da = {
    config: {
        container: '#au_databox_staff_utility',
        alphabet: 0,
        buttons: [{
            text: 'Institutter',
            link: ''
        }, {
            text: 'Fakulteter',
            link: ''
        }, {
            text: 'Fællesadministrationen',
            link: ''
        }, {
            text: 'Øvrige enheder',
            link: ''
        }],
        stacked: true
    },
    search: {
        index: 0
    },
    background: {
        color: '#37a0cb',
        overlay: false
    },
    data: {
        source: 'https://webtools.au.dk/Databox/Json/6'
    }
};
const DEFAULT_util_staffportal_en = {
    config: {
        container: '#au_databox_staff_utility',
        alphabet: 0,
        buttons: [{
            text: 'Departments',
            link: ''
        }, {
            text: 'Faculties',
            link: ''
        }, {
            text: 'Other units',
            link: ''
        }],
        stacked: true
    },
    search: {
        index: 0
    },
    background: {
        color: '#37a0cb',
        overlay: false
    },
    data: {
        source: 'https://webtools.au.dk/Databox/Json/7'
    }
};

// Default søgeboks til institutter til utility-box
const DEFAULT_util_department_da = {
    config: {
        container: '#au_databox_unit_utility',
        alphabet: 0,
        buttons: [{
            text: 'Arts',
            link: 'http://arts.au.dk'
        }, {
            text: 'Health',
            link: 'http://health.au.dk'
        }, {
            text: 'Natural Sciences',
            link: 'http://nat.au.dk'
        }, {
            text: 'Technical Sciences',
            link: 'http://tech.au.dk'
        }, {
            text: 'Aarhus BSS',
            link: 'http://bss.au.dk'
        }],
        stacked: true
    },
    search: {
        index: 0
    },
    background: {
        overlay: false,
        color: '#003260'
    },
    data: {
        source: 'https://webtools.au.dk/Databox/Json/47'
    }
};
const DEFAULT_util_department_en = {
    config: {
        container: '#au_databox_unit_utility',
        alphabet: 0,
        buttons: [{
            text: 'Arts',
            link: 'http://arts.au.dk/en/'
        }, {
            text: 'Health',
            link: 'http://health.au.dk/en/'
        }, {
            text: 'Natural Sciences',
            link: 'http://nat.au.dk/en/'
        }, {
            text: 'Technical Sciences',
            link: 'http://tech.au.dk/en/'
        }, {
            text: 'Aarhus BSS',
            link: 'http://bss.au.dk/en/'
        }],
        stacked: true
    },
    search: {
        index: 0
    },
    background: {
        overlay: false,
        color: '#003260'
    },
    data: {
        source: 'https://webtools.au.dk/Databox/Json/52'
    }
};

// Default alphabox med medarbejderservice til utility-box
const DEFAULT_util_staffservice_da = {
    config: {
        container: '#au_alphabox_staff_utility',
        title: 'Find værktøjer, vejledninger og serviceydelser',
        url: 'http://medarbejdere.au.dk/administration/',
        description: 'Find flere informationer om',
        links: [{
            title: 'HR',
            url: 'http://medarbejdere.au.dk/administration/hr/'
        }, {
            title: 'IT',
            url: 'http://medarbejdere.au.dk/administration/it/'
        }, {
            title: 'Økonomi',
            url: 'http://medarbejdere.au.dk/administration/oekonomi/'
        }, {
            title: 'Bygninger',
            url: 'http://medarbejdere.au.dk/administration/bygninger/'
        }, {
            title: 'Kommunikation',
            url: 'http://medarbejdere.au.dk/administration/kommunikation/'
        }, {
            title: 'Studieadm.',
            url: 'http://medarbejdere.au.dk/administration/studieadministration/'
        }, {
            title: 'Forskningspraksis',
            url: 'http://medarbejdere.au.dk/administration/forskning-talent/'
        }, {
            title: 'Internationalt',
            url: 'http://medarbejdere.au.dk/administration/internationalt/'
        }],
        noResultsAlphabet: 'Fandt du ikke, hvad du søgte?',
        noResultsSearch: 'Din søgning gav ingen resultater. Prøv nogle andre ord.',
        noResultsUri: 'https://medarbejdere.au.dk/administration/emneindeks',
    },
    search: {
        placeHolder: 'Søg i værktøjer m.m.'
    },
    data: {
        mode: 'json',
        source: 'https://webtools.au.dk/Databox/Json/8?itemsOnly=true'
    }
};
const DEFAULT_util_staffservice_en = {
    config: {
        container: '#au_alphabox_staff_utility',
        title: 'Find tools, guidelines and services',
        url: 'http://medarbejdere.au.dk/en/administration/',
        description: 'Find more information about',
        links: [{
            title: 'HR',
            url: 'http://medarbejdere.au.dk/en/administration/hr/'
        }, {
            title: 'IT',
            url: 'http://medarbejdere.au.dk/en/administration/it/'
        }, {
            title: 'Finance',
            url: 'http://medarbejdere.au.dk/en/administration/oekonomi/'
        }, {
            title: 'Buildings',
            url: 'http://medarbejdere.au.dk/en/administration/bygninger/'
        }, {
            title: 'Communication',
            url: 'http://medarbejdere.au.dk/en/administration/kommunikation/'
        }, {
            title: 'Studies adm.',
            url: 'http://medarbejdere.au.dk/en/administration/studieadministration/'
        }, {
            title: 'Research Practice',
            url: 'http://medarbejdere.au.dk/en/administration/researchandtalent/'
        }, {
            title: 'International',
            url: 'http://medarbejdere.au.dk/en/administration/international/'
        }],
        noResultsAlphabet: 'Didn\'t find what you were looking for?',
        noResultsSearch: 'Your search did not return any results. Please try other words.',
        noResultsUri: 'https://medarbejdere.au.dk/en/administration/index-for-staff-service',
    },
    search: {
        placeHolder: 'Search for tools, etc.'
    },
    data: {
        mode: 'json',
        source: 'https://webtools.au.dk/Databox/Json/9?itemsOnly=true'
    }
};

// Default søgeboks til ph.d.-portaler til utility-box
const DEFAULT_util_phdportal_da = {
    config: {
        container: '#au_databox_phd_utility',
        alphabet: 0,
        buttons: [{
            text: 'Arts',
            link: 'https://phd.arts.au.dk'
        }, {
            text: 'Health',
            link: 'https://phd.health.au.dk/'
        }, {
            text: 'Natural Sciences',
            link: 'https://phd.nat.au.dk/'
        }, {
            text: 'Technical Sciences',
            link: 'https://phd.tech.au.dk/'
        }, {
            text: 'Aarhus BSS',
            link: 'https://bss.au.dk/phd/'
        }],
        stacked: true
    },
    search: {
        index: 0
    },
    background: {
        overlay: false,
        color: '#003260'
    },
    data: {
        source: 'https://webtools.au.dk/Databox/Json/50'
    }
};
const DEFAULT_util_phdportal_en = {
    config: {
        container: '#au_databox_phd_utility',
        alphabet: 0,
        buttons: [{
            text: 'Arts',
            link: 'https://phd.arts.au.dk'
        }, {
            text: 'Health',
            link: 'https://phd.health.au.dk/'
        }, {
            text: 'Natural Sciences',
            link: 'https://phd.nat.au.dk/'
        }, {
            text: 'Technical Sciences',
            link: 'https://phd.tech.au.dk/'
        }, {
            text: 'Aarhus BSS',
            link: 'https://bss.au.dk/phd/'
        }],
        stacked: true
    },
    search: {
        index: 0
    },
    background: {
        overlay: false,
        color: '#003260'
    },
    data: {
        source: 'https://webtools.au.dk/Databox/Json/50'
    }
};

// Default alphabox med studieportaler til utility-box
const DEFAULT_util_student_da = {
    config: {
        container: '#au_alphabox_student_utility',
        title: 'Studieportaler',
        url: 'http://studerende.au.dk',
        links: []
    },
    search: {
        placeHolder: 'Søg efter studieportal'
    },
    background: {
        color: '#2a4a0f',
        linkColor: '#8bad3f'
    },
    data: {
        mode: 'json',
        source: 'https://webtools.au.dk/Databox/Json/2?itemsOnly=true'
    }
};
const DEFAULT_util_student_en = {
    config: {
        container: '#au_alphabox_student_utility',
        title: 'Study portals',
        url: 'http://studerende.au.dk/en/',
        links: []
    },
    search: {
        placeHolder: 'Search for Study portal'
    },
    background: {
        color: '#2a4a0f',
        linkColor: '#8bad3f'
    },
    data: {
        mode: 'json',
        source: 'https://webtools.au.dk/Databox/Json/4?itemsOnly=true'
    }
};

// Default alphabox med medarbejderservice til medarbejdere.au.dk (forside m.m.)
const DEFAULT_staff_staffservice_da = {
    config: {
        container: '#au_alphabox_1',
        title: 'Find værktøjer, vejledninger og serviceydelser',
        url: 'http://medarbejdere.au.dk/administration/',
        description: 'Find flere informationer om',
        links: [{
            title: 'HR',
            url: 'http://medarbejdere.au.dk/administration/hr/'
        }, {
            title: 'IT',
            url: 'http://medarbejdere.au.dk/administration/it/'
        }, {
            title: 'Økonomi',
            url: 'http://medarbejdere.au.dk/administration/oekonomi/'
        }, {
            title: 'Bygninger',
            url: 'http://medarbejdere.au.dk/administration/bygninger/'
        }, {
            title: 'Kommunikation',
            url: 'http://medarbejdere.au.dk/administration/kommunikation/'
        }, {
            title: 'Studieadm.',
            url: 'http://medarbejdere.au.dk/administration/studieadministration/'
        }, {
            title: 'Forskningspraksis',
            url: 'http://medarbejdere.au.dk/administration/forskning-talent/'
        }, {
            title: 'Internationalt',
            url: 'http://medarbejdere.au.dk/administration/internationalt/'
        }],
        noResultsAlphabet: 'Fandt du ikke, hvad du søgte?',
        noResultsSearch: 'Din søgning gav ingen resultater. Prøv nogle andre ord.',
        noResultsUri: 'https://medarbejdere.au.dk/administration/emneindeks',
    },
    search: {
        placeHolder: 'Søg i værktøjer m.m.'
    },
    data: {
        mode: 'json',
        source: 'https://webtools.au.dk/Databox/Json/8?itemsOnly=true'
    }
};
const DEFAULT_staff_staffservice_en = {
    config: {
        container: '#au_alphabox_1',
        title: 'Find tools, guidelines and services',
        url: 'http://medarbejdere.au.dk/en/administration/',
        description: 'Find more information about',
        links: [{
            title: 'HR',
            url: 'http://medarbejdere.au.dk/en/administration/hr/'
        }, {
            title: 'IT',
            url: 'http://medarbejdere.au.dk/en/administration/it/'
        }, {
            title: 'Finance',
            url: 'http://medarbejdere.au.dk/en/administration/oekonomi/'
        }, {
            title: 'Buildings',
            url: 'http://medarbejdere.au.dk/en/administration/bygninger/'
        }, {
            title: 'Communication',
            url: 'http://medarbejdere.au.dk/en/administration/kommunikation/'
        }, {
            title: 'Studies adm.',
            url: 'http://medarbejdere.au.dk/en/administration/studieadministration/'
        }, {
            title: 'Research Practice',
            url: 'http://medarbejdere.au.dk/en/administration/researchandtalent/'
        }, {
            title: 'International',
            url: 'http://medarbejdere.au.dk/en/administration/international/'
        }],
        noResultsAlphabet: 'Didn\'t find what you were looking for?',
        noResultsSearch: 'Your search did not return any results. Please try other words.',
        noResultsUri: 'https://medarbejdere.au.dk/en/administration/index-for-staff-service',
    },
    search: {
        placeHolder: 'Search for tools, etc.'
    },
    data: {
        mode: 'json',
        source: 'https://webtools.au.dk/Databox/Json/9?itemsOnly=true'
    }
};