/**
 * Titel: ALPHABOX
 * Beskrivelse: benyttes fx til medarbejderserviceboks
 * Status: er fuldstændig omskrevet til ES6/React og indlæses nu via components-biblioteket for at holde React ude af dette projekt
 */

$.ajaxSetup({
    cache: true
});

AU.alphabox = {
    boxes: [],
    scriptLoaded: false,
    loadExternal: function () {
        const ab = new AUAlphabox(AU.alphabox.boxes);
        ab.init();
    },
    init: function () {
        if (this.boxes.length > 0) {
            if (!this.scriptLoaded) {
                const componentsUrl = AU.cdn + '/components/umd/alphabox.js?v=' + AU.version;
                jQuery.getScript(componentsUrl, function () {
                    AU.alphabox.loadExternal();
                    AU.alphabox.scriptLoaded = true;
                });
            } else {
                this.loadExternal();
            }
        }
    }
};