/**
 * Titel: FALLBACK
 * Beskrivelse: består af den ældgamle 'pingAnalytics' til GA-tracking og nogle ukendte/LF-udviklede funktioner, der kan ske at være i brug rundt omkring...
 * Status: Do. Not. Touch.
 */

function pressed(url) {
    location.href = url;
}

function pres(url) {
    location.href = url;
}

function replaceall(s, x, y) {
    if (x.length > 0) {
        var found = s.indexOf(x);
        while (found >= 0) {
            s = s.substring(0, found) + y + s.substring(found + x.length, s.length);
            found = s.indexOf(x, found + y.length);
        }
    }
    return s;
}

function multipressed(xx, urlPattern, StartValue) {
    var url = urlPattern;
    var allow = StartValue;
    var OK = true;
    for (var i = 0; i < xx.elements.length; i++) {
        if (xx.elements[i].type === 'select-one') {
            if (OK) {
                OK = (xx.elements[i].selectedIndex >= allow);
            }
            url = replaceall(url, '$' + (i + 1), xx.elements[i].options[xx.elements[i].selectedIndex].value);
            url = replaceall(url, '¤' + (i + 1), xx.elements[i].options[xx.elements[i].selectedIndex].value);
        }
    }
    url = url.toLowerCase();
    if (OK) {
        location.href = url;
    } else {
        window.alert('Vælg en værdi i alle felter!');
    }
}

// En kommentar