/**
 * Titel: FLOWBOX
 * Beskrivelse: bruges primært til visning af kontaktpersoner på medarbejdersider, samt til åbningstider for it-supporten
 * Status: er fuldstændig omskrevet til ES6/React og indlæses nu via components-biblioteket for at holde React ude af dette projekt
 */

AU.flowbox = {
    boxes: [],
    scriptLoaded: false,
    loadExternal: function () {
        const fb = new AUFlowbox(AU.pageLang, AU.flowbox.boxes);
        fb.init();
    },
    init: function () {
        if (this.boxes.length > 0) {
            if (!this.scriptLoaded) {
                const componentsUrl = AU.cdn + '/components/umd/flowbox.js?v=' + AU.version;
                jQuery.getScript(componentsUrl, function () {
                    AU.flowbox.loadExternal();
                    AU.flowbox.scriptLoaded = true;
                });
            } else {
                this.loadExternal();
            }
        }
    }
}

