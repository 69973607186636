/**
 * Titel: HELPERS
 * Beskrivelse: hjælpemetoder som kan anvendes på tværs af alle scripts
 * Status: er kun i mindre grad blevet modificeret fra 2011/2015-udgaven
 */

AU.helpers = {
    getUnicodeKey: function (e) {
        var unicode = e.keyCode ? e.keyCode : e.charCode;
        return unicode;
    },
    rewriteLink: function (text) {
        return text.trim().toLowerCase().replace(/ /g, '_').replace(/æ/g, 'ae').replace(/ø/g, 'oe').replace(/å/g, 'aa');
    },
    getQuerystring: function (key, default_) {
        if (!default_) {
            default_ = '';
        }

        key = key.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + key + "=([^&#]*)");

        var qs = regex.exec(window.location.href);

        if (qs === null) {
            return default_;
        } else {
            return qs[1];
        }
    },
    getInternetExplorerVersion: function () {
        var rv = -1;
        if (navigator.userAgent.indexOf('Trident') > -1) {
            var ua = navigator.userAgent;
            var re = new RegExp("rv:([0-9]{1,}[.0-9]{0,})");
            if (re.exec(ua) !== null) {
                rv = parseFloat(RegExp.$1);
            }
        }
        return rv;
    },
    isMobileDevice: function () {
        return (/Mobile|iP(hone|od|ad)|Android|IEMobile/i).test(navigator.userAgent);
    },
    getXML: function (source, callback) {
        jQuery.ajax({
            type: 'GET',
            url: source,
            dataType: 'xml',
            success: function (data) {
                if (typeof callback === 'function') {
                    callback(data);
                }
            }
        });
    },
    isElementInViewport: function (element) {
        if (element === null) {
            return false;
        }

        var rect = element.getBoundingClientRect();

        return (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth));
    },
    isElementPartlyInViewport: function (element) {
        var top = element.offsetTop;
        var left = element.offsetLeft;
        var width = element.offsetWidth;
        var height = element.offsetHeight;

        while (element.offsetParent) {
            element = element.offsetParent;
            top += element.offsetTop;
            left += element.offsetLeft;
        }

        return (
            top < (window.pageYOffset + window.innerHeight) &&
            left < (window.pageXOffset + window.innerWidth) &&
            (top + height) > window.pageYOffset &&
            (left + width) > window.pageXOffset
        );
    },
    trimText: function (label, amount, end) {
        if (label.length > amount) {
            return label.substring(0, amount) + end;
        }

        return label;
    },
    validateEmail: function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    componentToHex: function (c) {
        var hex = c.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    },
    rgbToHex: function (r, g, b) {
        return this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    },
    getPageContent: function (url, retrieveSelector, insertSelector, callback) {
        var urlAllowed = function (url) {
            var protocolAndHost = location.protocol + '//' + location.host;
            return url.indexOf(protocolAndHost) === 0;
        };

        var insertElement = jQuery(insertSelector);
        if (urlAllowed(url)) {
            insertElement.load(url + ' ' + retrieveSelector, function () {
                if (typeof callback === 'function') {
                    callback();
                }
            });
            return true;
        } else {
            return false;
        }
    },
    accentMap: {
        'é': 'e',
        'ä': 'æ',
        'ö': 'ø',
        'á': 'a',
        'ü': 'y'
    },
    normalizeAccents: function (term, accentMap) {
        if (!accentMap) {
            accentMap = this.accentMap;
        }

        var ret = '';
        for (var i = 0; i < term.length; i++) {
            ret += accentMap[term.charAt(i)] || term.charAt(i);
        }
        return ret;
    }
};