/**
 * Titel: MAPS
 * Beskrivelse: Google Maps-baseret kortløsning.
 * Status: er noget af det ældste kode, vi har kørende. Virker som sådan fint nok, men ved et større kortprojekt ville det være oplagt at få omskrevet
 */

const GOOGLE_MAPS_API_KEY = "AIzaSyBP04DSgKVcqkYGO_Xb4GgqXHMzRO_2jzo";

AU.maps = {
    loadGoogleMapsScript: function () {
        // Check if the global variable for loading Google Maps is defined, if not default to false
        var shouldLoadGoogleMapsScript = typeof window.loadGoogleMapsScript !== 'undefined' ? window.loadGoogleMapsScript : false;
        var shouldLoadGoogleMapsScriptAsync = typeof window.loadGoogleMapsScriptAsync !== 'undefined' ? window.loadGoogleMapsScriptAsync : true; // Default to true for async

        // Early return if we should not load the Google Maps script
        if (shouldLoadGoogleMapsScript) {
            console.log("Google Maps script will be loaded manually on this page");
        } else{
            return;
        }

        // Construct the base URL with the API key
        var scriptUrl = 'https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_MAPS_API_KEY;

        // Conditionally add the async loading parameter to the URL
        if (shouldLoadGoogleMapsScriptAsync) {
            scriptUrl += '&loading=async';
        }

        scriptUrl += '&callback=AU.maps.googleMapsScriptCallback';

        // Create a script element and set the constructed URL
        var script = document.createElement('script');
        script.src = scriptUrl;

        // Handle the script load success
        script.onload = function () {
            console.log("Google Maps script has been loaded successfully. Now waiting for the callback to initialize.");
        };

        // Handle script load failure and capture details
        script.onerror = function (event) {
            console.error("Error loading Google Maps script:", event);
            console.error("Script URL:", event.target.src);  // URL of the failed script
        };

        // Append the script to the document's head to initiate the load
        document.head.appendChild(script);
    },
    // If the Google maps script is loaded, we use this callback to dispatch an googleMapsScriptLoadedEvent event
    googleMapsScriptLoaded: false,
    googleMapsScriptCallback: function () {
        AU.maps.googleMapsScriptLoaded = true;
        console.log("Google Maps script callback has been executed. Google Maps is ready for use.");

        // Dispatch a custom event to notify that Google Maps is ready
        const event = new CustomEvent('googleMapsScriptLoadedEvent');
        window.dispatchEvent(event);
    },
    create: function (container, width, height, zoomLevel, center, scrollWheel, draggable, clickable, streetView, pco, zco, svco) {

        var mapOptions = {
            zoom: zoomLevel,
            center: center,
            scrollwheel: (AU.helpers.isMobileDevice() || AU.responsive.state.NAME == 'PHONE') ? false : scrollWheel,
            draggable: draggable,
            clickable: clickable,
            streetViewControl: streetView,
            mapTypeControlOptions: {
                mapTypeIds: ['default', google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            panControlOptions: !pco ? {} : pco,
            zoomControlOptions: !zco ? {} : zco,
            streetViewControlOptions: !svco ? {} : svco
        };

        // Opretter kort
        var map = new google.maps.Map(container, mapOptions);

        // Kortets størrelse sættes
        container.style.width = !isNaN(width) ? width + 'px' : width;
        container.style.height = !isNaN(height) ? height + 'px' : height;

        return map;
    },
    setBounds: function (map, markers) {
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < markers.length; i++) {
            for (var j = 0; j < markers[i].length; j++) {
                bounds.extend(markers[i][j].position);
            }
        }
        map.fitBounds(bounds);
    },
    marker: {
        add: function (map, point, markerIcon, markerDraggable, markerClickable, markerPanTo, markerClickZoom, markerClickZoomLevel, infoWindow, windowContent, title, id) {
            var marker = new google.maps.Marker({
                position: point,
                map: map,
                icon: markerIcon,
                draggable: markerDraggable,
                title: title
            });

            if (typeof id !== 'undefined') {
                marker.set('id', id);
            }

            // Listeners tilføjes markør
            google.maps.event.addListener(marker, 'click', function () {
                if (windowContent !== '<p><strong></strong></p>') {
                    // Infowindow åbner ved klik
                    infoWindow.open(map, this);
                    infoWindow.setContent(windowContent);
                }

                if (markerClickable) {
                    if (markerPanTo) {
                        map.panTo(this.getPosition());
                    }

                    if (!markerClickZoom || map.getZoom() > markerClickZoomLevel) {
                        map.setZoom(map.getZoom());
                    } else {
                        map.setZoom(markerClickZoomLevel);
                    }
                }
            });

            // At flytte en markør betragtes som et klik på kortet (det giver tilsyneladende ingen problemer, skulle der ikke være defineret click-event på kortet)
            if (markerDraggable) {
                google.maps.event.addListener(marker, 'dragend', function (point) {
                    google.maps.event.trigger(map, 'click', point);
                });
            }

            return marker;
        }
    },
    route: {
        destinationFrom: false,
        destinationTo: false,
        directionsDisplay: null,
        directionsService: null,
        calc: function (map, from, to, travelMode, directionsService, directionsDisplay, waypointLocations) {
            if (from !== '') {
                var waypoints = [];
                if (typeof waypointLocations !== 'undefined') {
                    for (var i = 0; i < waypointLocations.length; i++) {
                        waypoints.push({
                            location: waypointLocations[i],
                            stopover: false
                        });
                    }
                }

                var request = {
                    origin: from,
                    destination: to,
                    travelMode: travelMode,
                    waypoints: waypoints
                };

                directionsService.route(request, function (response, status) {
                    if (status === google.maps.DirectionsStatus.OK) {
                        if (directionsDisplay.getMap() === null) {
                            directionsDisplay.setMap(map);
                        }
                        directionsDisplay.setDirections(response);
                    } else {
                        window.alert(status);
                    }
                });
            }
        },
        createDirectionObjects: function (map, container) {
            var rendererOptions = {
                draggable: true
            };

            var directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);
            var panel = container;

            directionsDisplay.setMap(map);
            directionsDisplay.setPanel(panel);

            return directionsDisplay;
        }
    },
    geocodePersonAddress: function (map, options, icon, address, centerMap, infoWindow, windowContent, func) {
        AU.maps.geocoder.geocode({
            'address': address
        }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var point = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());
                var marker = AU.maps.marker.add(map, point, icon, options.markerDrag, options.markerClick, options.clickPanTo, options.clickZoom, options.clickZoomLevel, infoWindow, windowContent, '');

                if (typeof func === 'function') {
                    func(marker, point, centerMap, infoWindow, windowContent);
                }

            }
        });

    },
    helpers: {
        getDistanceBetweenPoints: function (point1, point2) {
            return google.maps.geometry.spherical.computeDistanceBetween(point1, point2);
        },
        convertFromLatLng: function (coord) {
            return Math.round(coord * 1000000);
        },
        convertToLatLng: function (coord) {
            var c;
            if (coord.length === 7) {
                c = coord.substring(0, 1) + '.' + coord.substring(1);
            }
            if (coord.length === 8) {
                c = coord.substring(0, 2) + '.' + coord.substring(2);
            }
            if (coord.length === 9) {
                c = coord.substring(0, 3) + '.' + coord.substring(3);
            }
            return c;
        },
        getCurrentDate: function () {
            var d = new Date();
            var date = d.getDate();
            var month = d.getMonth() + 1;
            var currentDate = (date < 10 ? ('0' + date) : date) + '.' + (month < 10 ? ('0' + month) : month) + '.' + ('' + d.getFullYear()).substring(2);
            return currentDate;
        },
        getCurrentTime: function () {
            var d = new Date();
            var hours = d.getHours();
            var minutes = d.getMinutes();
            var currentTime = (hours < 10 ? ('0' + hours) : hours) + ':' + (minutes < 10 ? ('0' + minutes) : minutes);
            return currentTime;
        },
        sortSelect: function (element) {
            $(element).each(function () {
                var selectedValue = $(this).val();
                $(this).html($('option', $(this)).sort(function (a, b) {
                    return a.text === b.text ? 0 : a.text < b.text ? -1 : 1;
                }));
                $(this).val(selectedValue);
            });
        },
        sortAlphaObj: function (a, b) {
            if (typeof a.name !== 'undefined' && typeof b.name !== 'undefined') {
                return a.name > b.name ? 1 : -1;
            }
            return 0;
        }
    },
    infoWindow: null,
    geocoder: null
};

// Slut på det generelle ("biblioteket") – nedenstående omhandler de små kort, som (primært) sidder på institutsiderne

var maps = [];

AU.maps.single = {
    options: {
        image: '',
        markerDrag: !window._markerDrag ? false : window._markerDrag,
        markerClick: !window._markerClick ? true : window._markerClick,
        clickPanTo: !window._clickPanTo ? false : window._clickPanTo,
        clickZoom: !window._clickZoom ? false : window._clickZoom,
        clickZoomLevel: window._clickZoomLevel || 12,
        defaultZoomLevel: window._defaultZoomLevel || 16,
        scrollWheel: !window._scrollWheel ? false : window._scrollWheel,
        draggable: !window._draggable ? true : window._draggable,
        clickable: !window._clickable ? true : window._clickable,
        streetView: !window._streetView ? false : window._streetView,
        centerLat: window._centerLat || 56.20,
        centerLng: window._centerLng || 10.50,
        mapWidth: '99.5%',
        mapHeight: 275
    },
    center: null,
    maps: window.maps,
    mapDivs: [],
    firstMapLoaded: false,
    findFirstMap: function () {
        var i = 1;
        var firstMap;
        do {
            firstMap = document.getElementById('au_map' + (i === 1 ? '' : i));
            i++;
        } while (!firstMap && i <= 2);

        return firstMap;
    },
    loadMaps: function () {
        // Enten populeres kortet med manuelle punkter (fra sourcen) eller også henter vi fra datakilde (iPure / XML)
        if (AU.maps.single.maps.length > 0) {
            AU.maps.single.init();
        } else {
            if (typeof window.path !== 'undefined') {

                var dataFunc = function (data) {
                    var locations = data.locations;
                    var combinedPoints = [];
                    var name;
                    for (var l = 0; l < locations.length; l++) {
                        name = locations[l].names[0].name;
                        var lat = locations[l].latitude;
                        var lng = locations[l].longitude;
                        var url = locations[l].url;
                        var text = locations[l].text;

                        var points = [];
                        var point = {
                            latitude: lat,
                            longitude: lng,
                            title: name,
                            text: text
                        };
                        points.push(point);
                        combinedPoints.push(point);

                        if ($('#au_map').length > 0) {
                            AU.maps.single.maps.push({
                                name: name,
                                name_en: name,
                                container: '#au_map',
                                mapWidth: AU.maps.single.options.mapWidth,
                                mapHeight: AU.maps.single.options.mapHeight,
                                href: url,
                                points: points,
                                switchmode: 'dropdown',
                                load: false
                            });
                        }
                    }

                    if ($('#au_map2').length > 0) {
                        AU.maps.single.maps.push({
                            name: name,
                            name_en: name,
                            container: '#au_map2',
                            mapWidth: AU.maps.single.options.mapWidth,
                            mapHeight: AU.maps.single.options.mapHeight,
                            href: '',
                            points: combinedPoints,
                            load: true
                        });
                    }

                    AU.maps.single.init();
                };

                $.getJSON(window.path, function (data) {
                    dataFunc(data);
                });
            }
        }

        AU.maps.single.firstMapLoaded = true;
    },
    init: function () {

        AU.maps.route.directionsService = new google.maps.DirectionsService();
        AU.maps.infoWindow = new google.maps.InfoWindow();
        AU.maps.geocoder = new google.maps.Geocoder();

        var divUsed = false;
        for (var i = 0; i < AU.maps.single.maps.length; i++) {

            var currentMap = AU.maps.single.maps[i];

            currentMap.map = null;
            currentMap.markers = [];

            // Finder ud af, om vi allerede har brugt den angivne div
            for (var k = 0; k < AU.maps.single.mapDivs.length; k++) {
                if (AU.maps.single.mapDivs[k] === currentMap.container) {
                    divUsed = true;
                    break;
                } else {
                    divUsed = false;
                }
            }

            // Hvis vi ikke har brugt div'en, opretter vi kortet
            if (!divUsed) {
                var mapContainer = $(currentMap.container).get(0);
                var options = AU.maps.single.options;

                currentMap.map = AU.maps.create(mapContainer, currentMap.mapWidth, currentMap.mapHeight, options.defaultZoomLevel, AU.maps.single.center, options.scrollWheel, options.draggable, options.clickable, options.streetView);
                AU.maps.single.mapDivs.push(AU.maps.single.maps[i].container);

                if (currentMap.load) {
                    this.setMapContent(currentMap, false);
                }

                $(currentMap.container).after($('<div />', {
                    'class': 'au_maps_switchlink'
                }));

                if (typeof currentMap.switchmode !== 'undefined' && currentMap.switchmode === 'dropdown') {
                    $(currentMap.container + ' + div').append($('<select />'));
                }

            } else {
                // Ellers opretter vi en ny visning på det samme kort/den samme div
                for (var m = 0; m < AU.maps.single.maps.length; m++) {
                    if (AU.maps.single.maps[m].container === AU.maps.single.mapDivs[k]) {
                        currentMap.map = AU.maps.single.maps[m].map;
                        break;
                    }
                }
            }

            if (typeof currentMap.switchmode !== 'undefined') {
                if (currentMap.switchmode === 'links') {

                    var span = $('<span />', {
                        'text': ' | '
                    });

                    var link = $('<a />', {
                        'id': 'au_maps_switchlink_' + i,
                        'href': '#',
                        'text': AU.pageLang === 'da' ? currentMap.name : currentMap.name_en
                    });

                    span.append(link);
                    $(currentMap.container + ' + div').append(span);
                }

                if (currentMap.switchmode === 'dropdown') {
                    var option = $('<option />', {
                        'value': i,
                        'text': AU.pageLang === 'da' ? currentMap.name : currentMap.name_en
                    });

                    if (typeof window.selected !== 'undefined' && (currentMap.name === window.selected || currentMap.name_en === window.selected)) {
                        option.attr('selected', 'selected');
                    }

                    $(currentMap.container + ' + div select').append(option);
                }
            }
        }

        $('.au_maps_switchlink a').click(function (e) {

            e.preventDefault();

            var idSplit = $(this).attr('id').split('au_maps_switchlink_');
            if (idSplit.length === 2) {
                var id = idSplit[1];
                AU.maps.single.switchMode(id);
                $(AU.maps.single.maps[id].container + ' + div a').css('font-weight', 'normal');
                $(this).css('font-weight', 'bold');
            }
        });

        $.each($('.au_maps_switchlink'), function () {
            if ($('a', this).length > 1) {
                $('.au_maps_switchlink span:first-child a').trigger('click');
            }

            if ($('select', this).length > 0) {
                var id = $('select option:selected', this).val();
                AU.maps.single.switchMode(id);
            }
        });

        $('.au_maps_switchlink select').change(function () {
            var id = $(this).val();
            AU.maps.single.switchMode(id);
        });
    },
    switchMode: function (id) {
        this.setMapContent(maps[id], true);
        if (typeof maps[id].onchangeHybrid !== 'undefined') {
            if (maps[id].onchangeHybrid) {
                maps[id].map.setMapTypeId(google.maps.MapTypeId.HYBRID);
            } else {
                maps[id].map.setMapTypeId('default');
            }
        }
    },
    setMapContent: function (map, clear) {
        if (clear) {
            for (var i = 0; i < AU.maps.single.maps.length; i++) {
                if (map.map === AU.maps.single.maps[i].map) {
                    for (var n = 0; n < AU.maps.single.maps[i].markers.length; n++) {
                        AU.maps.single.maps[i].markers[n].setMap(null);
                    }
                    AU.maps.single.maps[i].markers = [];
                }
            }
        }

        if (map.points.length > 0) {
            var centerMap = false;
            var options = AU.maps.single.options;
            for (var j = 0; j < map.points.length; j++) {
                var title = !map.points[j].title ? '' : map.points[j].title;
                var text = !map.points[j].text ? ('<p><strong>' + title + '</strong></p>') : map.points[j].text;
                var point = new google.maps.LatLng(map.points[j].latitude, map.points[j].longitude);
                var marker = AU.maps.marker.add(map.map, point, options.image, options.markerDrag, options.markerClick, options.clickPanTo, options.clickZoom, options.clickZoomLevel, AU.maps.infoWindow, text, title);
                map.markers.push(marker);
                if (centerMap) {
                    map.setCenter(point);
                }
            }
            if (map.points.length > 1) {
                AU.maps.setBounds(map.map, [map.markers]);
            } else {
                map.map.setCenter(map.markers[0].position);
                map.map.setZoom(AU.maps.single.options.defaultZoomLevel);
            }
        }

        if (map.href !== null) {
            this.setMapClickEvent(map.map, map.href);
        }
    },
    setMapClickEvent: function (map, href) {
        google.maps.event.addListener(map, 'click', function (p) {
            window.location = href;
        });
    }
};

AU.maps.widget = {
    googleMaps: true,
    widgets: [],
    constants: {
        BUILDING: -1,
        DEPARTMENT: -1,
        OVERLAY: -1,
        markerWidth: 24,
        markerHeight: 33,
        overlayMarkerWidth: 24,
        overlayMarkerHeight: 33,
        centerLat: 56.168995,
        centerLng: 10.203113
    },
    options: {
        config: {
            container: '#universe-utility-map',
            mapContainer: 'au_map_widget',
            pin: true,
            image: '',
            defaultZoomLevel: 17,
            markerDrag: false,
            markerClick: false,
            clickPanTo: false,
            clickZoom: false,
            clickZoomLevel: 17
        }
    },
    data: {
        source: {
            data: "//typo3.au.dk/maps/xmltojson.php"
        },
        locations: []
    },
    loadMap: function (widget) {
        if (widget.mapContainer && this.googleMaps) {
            widget.map = AU.maps.create(widget.mapContainer, '100%', 190, widget.config.defaultZoomLevel, new google.maps.LatLng(56.169046, 10.203107), true, true, false, false);
            widget.loaded = true;
            google.maps.event.trigger(widget.map, 'resize');
        }
    },
    loadData: function (widget) {

        var dataFunc = function (data) {

            var w = AU.maps.widget;

            w.constants.BUILDING = data.constants[0].building;
            w.constants.DEPARTMENT = data.constants[0].department;
            w.constants.OVERLAY = data.constants[0].overlay;

            w.data.locations = data.locations;
            w.setAutoCompletes(widget);

            w.checkForSearchQuery(widget);
        };

        var ieVersion = AU.helpers.getInternetExplorerVersion();
        if (ieVersion > 6 && ieVersion < 10) {
            var xdr = window.XDomainRequest ? new XDomainRequest() : null;
            if (xdr !== null) {
                xdr.onload = function () {
                    dataFunc(jQuery.parseJSON(xdr.responseText));
                };

                xdr.onprogress = function () {
                };

                xdr.open('get', AU.maps.widget.data.source.data);
                xdr.send();
            }

        } else {
            jQuery.getJSON(this.data.source.data, function (data) {
                dataFunc(data);
            });
        }

    },
    setAutoCompletes: function (widget) {

        var search = [];
        for (var i = 0; i < this.data.locations.length; i++) {
            var loc = this.data.locations[i];
            for (var n = 0; n < loc.names.length; n++) {
                search.push({
                    id: loc.id,
                    label: loc.names[n].name,
                    value: loc.names[n].name,
                    type: loc.type
                });
            }
        }

        // Lokationer
        var acLocOptions = {
            source: search,
            minLength: 3,
            autoFocus: true,
            select: function (event, ui) {

                var id = ui.item.id;
                var w = AU.maps.widget;
                if (ui.item.type === w.constants.BUILDING) {
                    w.setBuildingMarker(widget, w.getBuilding(id), true, true, true);
                } else if (ui.item.type === w.constants.DEPARTMENT) {
                    w.setDepartmentMarkers(widget, w.getDepartment(id));
                } else {
                    w.setOverlayMarker(widget, w.getOverlay(id), true, true, true, true);
                }
            }
        };

        // Personsøgning
        var acPeopleOptions = {
            source: function (request, response) {
                var search = request.term;
                if (typeof String.prototype.trim === "function") {
                    search = search.trim();
                }
                AU.helpers.getXML(AU.ipure.source.searchCrossSite + encodeURI(search), function (data) {
                    response(AU.ipure.getPeople(data));
                });
            },
            minLength: 3,
            autoFocus: true,
            select: function (event, ui) {
                AU.helpers.getXML(AU.ipure.source.searchDetailsCrossSite + ui.item.url, function (data) {
                    AU.maps.widget.setPersonMarker(widget, data);
                });
            }
        };

        jQuery('.au_maps_widget_acl', widget.container).autocomplete(acLocOptions);
        jQuery('.au_maps_widget_acp', widget.container).autocomplete(acPeopleOptions);

    },
    checkForSearchQuery: function (widget) {
        if (!widget.pin && AU.helpers.getQuerystring('q') !== "") {

            var getBuildingString = function (query) {

                var containsBuildingNumber = function (query) {
                    var re = new RegExp('(\\d{4})', 'ig');
                    return query.match(re);
                };

                var cbn = containsBuildingNumber(query);
                if (cbn !== null) {
                    return cbn[0];
                }

                return "";

            };

            var query = decodeURI(AU.helpers.getQuerystring('q'));
            if (typeof String.prototype.trim === "function") {
                query = query.trim();
            }

            var bs = getBuildingString(query);
            if (bs !== "") {
                var bldg = this.getBuilding(bs);
                if (bldg !== null) {
                    this.setBuildingMarker(widget, bldg, true, true, true);
                    jQuery('.au_maps_widget_acl', widget.container).val((AU.pageLang === "da" ? 'Bygning ' : 'Building ') + bldg.id);
                }
            } else {
                var querySplit = query.split(" ");
                if (querySplit.length > 1 || query.indexOf('+') > -1) {
                    query = query.replace(/\+/g, ' ');
                    AU.helpers.getXML(AU.ipure.source.searchCrossSite + encodeURI(query), function (data) {
                        var people = AU.ipure.getPeople(data);
                        if (people.length === 1) {
                            AU.helpers.getXML(AU.ipure.source.searchDetailsCrossSite + people[0].url, function (data) {
                                AU.maps.widget.setPersonMarker(widget, data);
                                jQuery('.au_maps_widget_acp', widget.container).val(query);
                            });
                        } else if (people.length > 1) {
                            jQuery('.au_maps_widget_acp', widget.container).val(query).focus().autocomplete('search');
                        }
                    });

                }
            }


        }

    },
    clearMarkers: function (widget) {
        for (var i = 0; i < widget.markers.length; i++) {
            widget.markers[i].setMap(null);
        }
        widget.markers = [];
    },
    setBuildingMarker: function (widget, building, clearOld, centerMap, showContent) {

        if (AU.maps.widget.googleMaps) {
            if (clearOld) {
                this.clearMarkers(widget);
            }

            var point = new google.maps.LatLng(building.latitude, building.longitude);
            var icon = new google.maps.MarkerImage(widget.config.image, null, null, null, new google.maps.Size(this.constants.markerWidth, this.constants.markerHeight));
            var windowContent = '<p><a href="//www.au.dk/' + (AU.pageLang === "da" ? 'kort' : 'en/map') + '?b=' + building.id + '">' + (AU.pageLang === "da" ? 'Vis detajler på bygningskortet.' : 'Show details on the building map.') + '</a></p>';

            var marker = AU.maps.marker.add(widget.map, point, icon, false, false, false, false, widget.config.defaultZoomLevel, AU.maps.infoWindow, windowContent, building.names[0].name);
            widget.markers.push(marker);

            if (centerMap) {
                widget.map.setCenter(point);
            }
        }

        widget.content.empty();
        if (showContent) {
            widget.content.append('<h2><strong>' + (AU.pageLang === "da" ? 'Bygning ' : 'Building ') + building.id + '</strong> - ' + building.address + '</h2>');
        }
    },
    setDepartmentMarkers: function (widget, department) {
        AU.helpers.getXML(AU.ipure.source.searchDetailsWrapper + department.departmentsdetails, function (data) {
            var clearOld = false;
            var centerMap = false;

            var w = AU.maps.widget;

            var addressNode = jQuery(data).find('address');
            var street = addressNode.attr('street');
            var zip = addressNode.attr('zip');
            var city = addressNode.attr('city');
            var address = street + ", " + zip + " " + city;

            var buildingNode = jQuery(data).find('building');
            var buildings = [];

            // Bygninger ligger ikke direkte som en datakilde
            for (var i = 0; i < buildingNode.length; i++) {
                var building = w.getBuilding(jQuery(buildingNode[i]).attr('number'));
                if (building !== null) {
                    buildings.push(building);
                }
            }

            // Løber alle bygninger igennem og placerer markører
            if (buildings.length > 0) {
                for (var k = 0; k < buildings.length; k++) {
                    w.setBuildingMarker(widget, buildings[k], clearOld, centerMap, false);
                }
                if (AU.maps.widget.googleMaps) {
                    AU.maps.setBounds(widget.map, [widget.markers]);
                }
            } else {
                if (AU.maps.widget.googleMaps) {
                    // Hvis vi ikke finder nogle bygninger, AU.maps.geocoder vi på baggrund af enhedens adresse
                    AU.maps.geocoder.geocode({
                        'address': address
                    }, function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            var point = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());
                            var options = widget.config;
                            var icon = new google.maps.MarkerImage(options.image, null, null, null, new google.maps.Size(w.constants.markerWidth, w.constants.markerHeight));
                            var marker = AU.maps.marker.add(widget.map, point, icon, false, false, false, false, options.defaultZoomLevel, AU.maps.infoWindow, "", "");
                            widget.markers.push(marker);
                            widget.map.setCenter(point);
                        }
                    });
                }
            }

            widget.content.empty();
            widget.content.append('<h2>' + department.names[0].name + '</h2><p><a href="//www.au.dk/' + (AU.pageLang === "da" ? 'kort' : 'en/map') + '?e=' + department.id + '">' + (AU.pageLang === "da" ? 'Vis detaljer om enheden på bygningskortet.' : 'Show details for department on the building map.') + '</a></p>');

        });
    },
    setOverlayMarker: function (widget, place, clearOld, centerMap) {

        var windowContent = '<p><a href="//www.au.dk/' + (AU.pageLang === "da" ? 'kort' : 'en/map') + '?o=' + place.groupid + '">' + (AU.pageLang === "da" ? 'Vis alle "' + place.groupname + '"-lokationer på bygningskortet.' : 'Show all "' + place.groupname + '" locations on building map.') + '</a></p>';

        if (AU.maps.widget.googleMaps) {
            if (clearOld) {
                this.clearMarkers(widget);
            }

            var point = new google.maps.LatLng(place.latitude, place.longitude);

            var icon;
            if (place.icon.indexOf('generic') === -1) {
                icon = new google.maps.MarkerImage(place.icon, null, null, null, new google.maps.Size(this.constants.overlayMarkerWidth, this.constants.overlayMarkerHeight));
            } else {
                icon = new google.maps.MarkerImage(place.icon, null, null, null, new google.maps.Size(this.constants.markerWidth, this.constants.markerHeight));
            }

            var marker = AU.maps.marker.add(widget.map, point, icon, false, false, false, false, widget.config.defaultZoomLevel, AU.maps.infoWindow, windowContent, "", place.id);
            widget.markers.push(marker);

            if (centerMap) {
                widget.map.setCenter(point);
            }
        }

        widget.content.empty();
        widget.content.append('<h2>' + place.names[0].name + '</h2>' + windowContent);
    },
    setPersonMarker: function (widget, data) {
        AU.maps.widget.clearMarkers(widget);
        AU.ipure.getPersonDetails(data, function (personHTML, buildingNumber, email, officePhone, address, centerMap) {

            var w = AU.maps.widget;
            var icon = w.googleMaps ? new google.maps.MarkerImage(widget.config.image, null, null, null, new google.maps.Size(w.constants.markerWidth, w.constants.markerHeight)) : null;
            var iw = w.googleMaps ? new google.maps.InfoWindow() : null;
            var windowContent = '<p><a href="//www.au.dk/' + (AU.pageLang === "da" ? 'kort' : 'en/map') + '?p=' + email + '">' + (AU.pageLang === "da" ? 'Vis på bygningskortet.' : 'Show on the building map.') + '</a></p>';
            if (typeof buildingNumber === "undefined" && w.googleMaps) {
                AU.maps.geocodePersonAddress(widget.map, widget.config, icon, address, centerMap, iw, windowContent, function (marker, point, centerMap, infoWindow, windowContent) {
                    widget.content.empty();
                    widget.content.append(personHTML);
                    widget.markers.push(marker);

                    if (w.googleMaps) {
                        if (centerMap) {
                            widget.map.setCenter(point);
                        } else {
                            AU.maps.setBounds(widget.map, [widget.markers]);
                        }
                    }
                });
            } else {

                if (w.googleMaps) {
                    var building = w.getBuilding(buildingNumber);
                    w.setBuildingMarker(widget, building, true, centerMap, false);
                }
                widget.content.empty();
                widget.content.append(personHTML);
            }


        });
    },
    getBuilding: function (number) {
        var building = null;
        for (var i = 0; i < this.data.locations.length; i++) {
            if (this.data.locations[i].type === this.constants.BUILDING && this.data.locations[i].id === number.toString()) {
                building = this.data.locations[i];
                break;
            }
        }
        return building;
    },
    getDepartment: function (departmentId) {
        var department = null;
        for (var i = 0; i < this.data.locations.length; i++) {
            if (this.data.locations[i].type === this.constants.DEPARTMENT && this.data.locations[i].id === departmentId.toString()) {
                department = this.data.locations[i];
                break;
            }
        }
        return department;
    },
    getOverlay: function (id) {
        var place;
        for (var i = 0; i < this.data.locations.length; i++) {
            if (this.data.locations[i].type === this.constants.OVERLAY && this.data.locations[i].id === id.toString()) {
                place = this.data.locations[i];
            }
        }
        return place;
    },
    mergeOptions: function (widget) {
        if (!widget.hasOwnProperty('config')) {
            widget.config = {};
        }

        for (var conf in this.options.config) {
            if (!widget.config.hasOwnProperty(conf)) {
                widget.config[conf] = this.options.config[conf];
            }
        }
    },
    init: function () {
        if (typeof google === "undefined") {
            AU.maps.widget.googleMaps = false;
        }

        if (AU.maps.widget.googleMaps) {

            AU.maps.route.directionsService = new google.maps.DirectionsService();
            AU.maps.infoWindow = new google.maps.InfoWindow();
            AU.maps.geocoder = new google.maps.Geocoder();

        }

        for (var i = 0; i < this.widgets.length; i++) {
            var wdgt = this.widgets[i];
            this.mergeOptions(wdgt);
            wdgt.container = jQuery(wdgt.config.container);
            wdgt.mapContainer = document.getElementById(wdgt.config.mapContainer);
            wdgt.content = jQuery('.au_maps_widget_content', wdgt.container);
            wdgt.markers = [];
            wdgt.loaded = false;

            if (!wdgt.config.pin) {
                this.loadMap(wdgt);
                this.loadData(wdgt);
            }

        }
    }
};